
import { getCommonParams, getListMainParams } from './common.js'
import { cleanNullValueObjectFirstLevel, getGoodsAdp } from './utils.js'


// 商品接口入参
const getGoodsParams = (options) => {

  const {
    catInfo,
    request
  } = options
  
  const query = request.query || {}
  const { mainParams } = getListMainParams(options)

  const isStorePage = ['storeHomePage', 'storeTemplatePage'].includes(catInfo?.src_module) || catInfo.fromPageType === 'store' || catInfo.store_code // 待整理

  const { 
    date,
    newSrcTabPageId,
    quickship,
    filter,
    catId,
    filterBrandIds,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    channelId,
    brandId,
    urlMallCode,
    filterMallCode,
    storeCode,
    sort,
    source,
    sourceStatus,
    filter_goods_infos
  } = getCommonParams(options)

  let params = {
    ...mainParams,
    brand_id: brandId,
    date,
    quickship,
    filter,
    filterBrandIds,
    cat_ids: query.cat_ids,
    cat_id: catId,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    channelId,
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    page_name: catInfo.pageName,
    src_tab_page_id: newSrcTabPageId || '-',
    entranceType: catInfo.entranceType || '',
    sort,
    source,
    styleType: query.styleType || '',
    source_status: sourceStatus || '',
    adp: getGoodsAdp(query),
    page: query.page || 1,
    limit: catInfo.limit || 120,
    store_code: storeCode,
    from_store: Number(!!isStorePage),
    filter_goods_infos,
    _type: 'selection'
  }


  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}


// 筛选接口入参（图文导航，属性，分类，tsp筛选）
const getFilterParams = (options) => {

  const {
    catInfo,
    request
  } = options
  
  const query = request.query || {}
  const { mainParams } = getListMainParams(options)

  const { 
    date,
    quickship,
    filter,
    catId,
    filterTagIds,
    brandId,
    maxPrice,
    minPrice,
    tagIds,
    chooseIds,
    filterBrandIds,
    urlMallCode,
    filterMallCode,
    sort
  } = getCommonParams(options)

  let params = { 
    ...mainParams,

    brand_id: brandId,
    date,
    quickship,
    filter,
    filterBrandIds,
    cat_ids: query.cat_ids,
    cat_id: catId,
    choosed_ids: chooseIds,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    mall_code_list: urlMallCode,
    choosed_mall_code: filterMallCode,
    page_name: catInfo.pageName,
    sort,
    styleType: query.styleType || '',
    showAllCategory: '1',
    selectAttributeGroup: query.selectAttributeGroup || '', // 选中的属性组合

    _type: 'selection'
  }


  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 标签云接口入参
const getCloudTagParams = (options) => {

  const {
    catInfo,
    request
  } = options
  
  const query = request.query || {}
  const { mainParams } = getListMainParams(options)

  const isStorePage = ['storeHomePage', 'storeTemplatePage'].includes(catInfo?.src_module) || catInfo.fromPageType === 'store' || query.store_code 

  const { 
    quickship,
    filter,
    catId,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    urlMallCode,
    filterMallCode,
    sort
  } = getCommonParams(options)

  const isQuickShip = query.tag_ids === 'quickship' // 标签云筛选了quickship
  const isMallTag = query.mall_tag || ''

  let choosed_tag = ''
  if (isQuickShip) {
    choosed_tag = 'quickship'
  } else if (!isMallTag) {
    choosed_tag = tagIds
  }

  let params = {
    ...mainParams,

    quickship,
    filter,
    cat_ids: query.cat_ids,
    child_cat_id: catId,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    choosed_tag,
    choosed_mall_code: filterMallCode,
    mall_code_list: urlMallCode,
    sort,
    page_name: catInfo.pageName,
    page: query.page || 1,
    limit: catInfo.limit || 120,
    styleType: query.styleType || '',

    from_store: Number(!!isStorePage),

    _type: 'selection'
  }


  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}


export const getSelectionGenerateBffParams = (options) => {
  return {
    goodsParams: getGoodsParams(options),
    filterParams: getFilterParams(options),
    cloudTagParams: getCloudTagParams(options)
  }
}
