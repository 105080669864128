
// 请求参数处理
// 根据 switchBff 判断是否融合走不同链路
import { bffSwitchPageKeyMap } from '../const.js'

import { getWebFetchParams } from './webparamsHandle.js'
import { getSearchGenerateBffParams, getSelectionGenerateBffParams, getEntityGenerateBffParams } from './bffParamsHandle/index.js'

import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index'

/**
 * 判断当前请求类型，返回相关参数
 * @param {
 *  searchKeywords: Object, // 搜索词相关
 *  catInfo: Object,        // 请求参数
 *  atomicParams: Object,   // 原子参数
 *  viewedGoods: Array,     // 已浏览商品集合
 *  request: Object         // 请求参数
 * } options 
 */
export const getFetchParams = (options) => {
  const {
    catInfo
  } = options

  const type = catInfo?.type

  const productFilterBffKey = bffSwitchPageKeyMap.get(type)
  const productFilterSwitchBff = isSwitchBffApiVersion([productFilterBffKey])?.[productFilterBffKey]

  let paramsData = {
    // node主接口
    nodeParams: getWebFetchParams(options) || {},

    // bff的请求参数
    goodsParams: null,
    filterParams: null,
    cloudTagParams: null,
    categoryDayTagParams: null
  }

  // bff请求
  if (productFilterSwitchBff) {
    // 切掉主接口商品和筛选请求
    paramsData.nodeParams.useBffProducts = true
    paramsData.nodeParams.useBffFilters = true

    switch (type) {
      case 'search':
        paramsData = Object.assign(paramsData, getSearchGenerateBffParams(options))
        break
      case 'entity':
        paramsData = Object.assign(paramsData, getEntityGenerateBffParams(options))
        break
      case 'selection':
        paramsData = Object.assign(paramsData, getSelectionGenerateBffParams(options))
        break
    }
  }

  return paramsData
}
