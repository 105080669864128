// 商品以及筛选的通用参数处理
export const getCommonParams = (options) => {
  const { request, catInfo, viewedGoods } = options

  const query = request.query || {}

  const isQuickShip = query.tag_ids === 'quickship' // 标签云筛选了quickship
  const isMallTag = query.mall_tag || ''

  const filterMallCode = isMallTag ? query.tag_ids : '' //  用户点击mall标签时，对应mall标签的值
  const urlMallCode = query.mallCode || '' // url中的mallCode

  const isInListSearch = !(query.sil_without_entry || !query.sil_type) // 是否是列表页内搜索

  let filter = query.attr_ids || '' // 属性筛选

  let tagIds = isQuickShip || isMallTag ? '' : query.tag_ids // 标签云筛选
  if (['0', 0].includes(tagIds)) {
    tagIds = ''
  }


  let newSrcTabPageId = typeof catInfo.new_src_tab_page_id === 'string' ? catInfo.new_src_tab_page_id : '-'

  return {
    filter, // 属性筛选
    tagIds, // 标签云筛选

    newSrcTabPageId: newSrcTabPageId || '-',

    date: query.daily || '',
    catId: query.child_cat_id || '', // 分类筛选
    chooseIds: query.choose_ids || '', // 当前选中的category标签集合

    urlMallCode,
    filterMallCode,
    mallCode: filterMallCode || urlMallCode, // 优先取值mall标签筛选，其次取url中的mallCode

    maxPrice: query.max_price ?? '', // 最大价格
    minPrice: query.min_price ?? '', // 最小价格
    filterTagIds: query.tsp_ids || '', // tsp标签筛选，“1_60001803,1_60001803,2_60001803”（需要带标签组）
    quickship: isQuickShip ? '1' : '', // quickship=1表示过滤快速发货，quickship=0表示过滤非快速发货，quickship不传表示不过滤
    listCatId: isInListSearch ? query.list_cat_id : '', // 商品列表分类ID

    intentChannelId: query.intent_channel_id || '', // 频道筛选ID
    brandId: catInfo.brand_id || '', // 品牌id
    filterBrandIds: query.brand_ids || '', // 品牌筛选
    storeCode: query.store_code || '', // 店铺code， 自营店铺为1， 默认为空

    sort: query.sort || '',
    source: catInfo.source || '',
    sourceStatus: catInfo.source_status || '',
    channelId: catInfo.channel_id || '', // 人群id
    limit: catInfo.limit || 120,

    // 已浏览的商品
    filter_goods_infos: (viewedGoods || []).join(',')
  }
}

export const getListMainParams = (options) => {
  const { catInfo } = options

  const type = catInfo?.type

  if (catInfo?._type === 'sheinPicks') {
    const subTypeTotypeId = new Map([
      ['entity', 1],
      ['selection', 9],
    ])
    return {
      type: 'sheinPicks',
      mainParams: {
        type: subTypeTotypeId.get(type),
        cat_id: catInfo.picks_id,
      },
    }
  } else {
    switch (type) {
      case 'search':
        return {
          type: 'search',
          mainParams: {
            cate_type: 4,
            keywords: catInfo.keywords || '',
          },
        }
      case 'entity':
        return {
          type: 'entity',
          mainParams: {
            cat_id: catInfo?.entity_id,
            cate_type: 2,
          },
        }
      case 'selection':
        return {
          type: 'selection',
          mainParams: {
            select_id: catInfo?.select_id,
            cate_type: 1,
          },
        }
      case 'selling':
        return {
          type: 'selling',
          mainParams: {
            tag_val_id: catInfo?.tag_val_id,
          },
        }
      default:
        return {
          mainParams: {},
        }
    }
  }
}
